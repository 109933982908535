export const SUPPLEMENTARY_DATA_CREATE_REQUESTING = 'SUPPLEMENTARY_DATA_CREATE_REQUESTING';
export const SUPPLEMENTARY_DATA_CREATE_SUCCESS = 'SUPPLEMENTARY_DATA_CREATE_SUCCESS';
export const SUPPLEMENTARY_DATA_CREATE_ERROR = 'SUPPLEMENTARY_DATA_CREATE_ERROR';
export const SUPPLEMENTARY_DATA_UPDATE_REQUESTING = 'SUPPLEMENTARY_DATA_UPDATE_REQUESTING';
export const SUPPLEMENTARY_DATA_UPDATE_SUCCESS = 'SUPPLEMENTARY_DATA_UPDATE_SUCCESS';
export const SUPPLEMENTARY_DATA_UPDATE_ERROR = 'SUPPLEMENTARY_DATA_UPDATE_ERROR';
export const SUPPLEMENTARY_DATA_RESET_STATES = 'SUPPLEMENTARY_DATA_RESET_STATES';
export const SUPPLEMENTARY_DATA_FILL_DATA = "SUPPLEMENTARY_DATA_FILL_DATA";
export const SUPPLEMENTARY_DATA_FILL_DATA_SUCCESS = "SUPPLEMENTARY_DATA_FILL_DATA_SUCCESS";
export const SUPPLEMENTARY_DATA_FILL_DATA_ERROR = "SUPPLEMENTARY_DATA_FILL_DATA_ERROR";
export const SUPPLEMENTARY_DATA_CLEAN_VALUES = "SUPPLEMENTARY_DATA_CLEAN_VALUES";