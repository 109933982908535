import { SURE_TYPES_GET_ERROR, SURE_TYPES_GET_REQUESTING, SURE_TYPES_GET_SUCCESS } from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	sureTypes: []
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SURE_TYPES_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case SURE_TYPES_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				sureTypes: action.sureTypes
			};
		case SURE_TYPES_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		default:
			return state;
	}
};

export default reducer;