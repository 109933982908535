import {
	POLICY_CREATE_ERROR,
	POLICY_CREATE_REQUESTING,
	POLICY_CREATE_SUCCESS, POLICY_RESET_FOCUS,
	POLICY_UPDATE_ERROR,
	POLICY_UPDATE_REQUESTING,
	POLICY_UPDATE_SUCCESS,
	POLICY_DELETE_ERROR,
	POLICY_DELETE_REQUESTING,
	POLICY_DELETE_SUCCESS,
	POLICY_UPDATE_PRICE_ERROR,
	POLICY_UPDATE_PRICE_REQUESTING,
	POLICY_UPDATE_PRICE_SUCCESS,
	POLICY_CLEAN_VALUES
} from "./constants";

export const policyCreateRequesting = (token, values) => ({
	type: POLICY_CREATE_REQUESTING,
	token, values
});

export const policyCreateSuccess = (policy) => ({
	type: POLICY_CREATE_SUCCESS,
	policy
});

export const policyCreateError = (error) => ({
	type: POLICY_CREATE_ERROR,
	error
});

export const policyUpdateRequesting = (token, policyId, values) => ({
	type: POLICY_UPDATE_REQUESTING,
	token, policyId, values
});

export const policyUpdateSuccess = (policy) => ({
	type: POLICY_UPDATE_SUCCESS,
	policy
});

export const policyUpdateError = (error) => ({
	type: POLICY_UPDATE_ERROR,
	error
});

export const policyUpdatePriceRequesting = (token, policyId, values) => ({
	type: POLICY_UPDATE_PRICE_REQUESTING,
	token, policyId, values
});

export const policyUpdatePriceSuccess = (policy) => ({
	type: POLICY_UPDATE_PRICE_SUCCESS,
	policy
});

export const policyUpdatePriceError = (error) => ({
	type: POLICY_UPDATE_PRICE_ERROR,
	error
});

export const policyDeleteRequesting = (token, policyId) => ({
	type: POLICY_DELETE_REQUESTING,
	token,
	policyId
});

export const policyDeleteSuccess = () => ({
	type: POLICY_DELETE_SUCCESS,
});

export const policyDeleteError = (error) => ({
	type: POLICY_DELETE_ERROR,
	error
});

export const policyResetFocus = () => ({
	type: POLICY_RESET_FOCUS,
});

export const policyCleanValues = () => ({
	type: POLICY_CLEAN_VALUES
});
