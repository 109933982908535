import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import { onerosoGetError, onerosoGetSuccess , onerosoSetCreditIdSuccess , onerosoSetCreditIdError } from "./actions";
import { ONEROSO_GET_REQUESTING , ONEROSO_SET_CREDIT_ID_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/oneroso`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const onerosoGetApi = () => {
	return fetch(`${baseUrl}`, {
		method: 'GET',
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 400)
				throw [json.data];
			if (json.code === 422)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		})
};

const onerosoSetCreditIdApi = (onerosoId,creditId) => {
	return fetch(`${baseUrl}/${onerosoId}/${creditId}`, {
		method: 'PUT',
	})
	  .then(response => response.json())
	  .then(json => {
	  	if (json.code === 400)
	  		throw [json.data];
	  	if (json.code === 422)
	  		throw json.data;
	  	if (json.code === 200)
	  		return json.data.data;
	  	throw json.data;
	  }).catch((error) => {
	  	 throw error;
	  });
};

function* onerosoGetFlow() {
	try {
		const oneroso = yield call(onerosoGetApi);
		yield put(onerosoGetSuccess(oneroso));
	} catch (error) {
		yield put(onerosoGetError(error));
	}
}

function* onerosoSetCreditIdFlow(action){
	try{
		const { onerosoId , creditId } = action;
		yield call(onerosoSetCreditIdApi , onerosoId , creditId);
		yield put(onerosoSetCreditIdSuccess());
		toastr.success("Registrado" , "Registrado exitosamente.");
	}catch (error){
		yield put(onerosoSetCreditIdError(error));
		showError(error);
	}
}

function* onerosoWatcher() {
	yield all([
		takeEvery(ONEROSO_GET_REQUESTING, onerosoGetFlow),
		takeEvery(ONEROSO_SET_CREDIT_ID_REQUESTING, onerosoSetCreditIdFlow)
	])
}

export default onerosoWatcher;