import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { featuresSureTypeGetError, featuresSureTypeGetSuccess } from "./actions";
import { FEATURES_SURE_TYPE_GET_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/features`;

const featuresSureTypeGetApi = (token, objectId) => {
	return fetch(`${baseUrl}/${objectId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 400)
				throw [json.data];
			if (json.code === 422)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* featuresSureTypeGetFlow(action) {
	try {
		const { token, objectId } = action;
		const featuresSureType = yield call(featuresSureTypeGetApi, token, objectId);
		yield put(featuresSureTypeGetSuccess(featuresSureType));
	} catch (error) {
		yield put(featuresSureTypeGetError(error));
	}
}

function* featureWatcher() {
	yield all([
		takeEvery(FEATURES_SURE_TYPE_GET_REQUESTING, featuresSureTypeGetFlow)
	]);
}

export default featureWatcher;