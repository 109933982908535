import {
    STATE_RESET_STATES,
    STATES_GENERAL_GET_ERROR,
    STATES_GENERAL_GET_REQUESTING,
    STATES_GENERAL_GET_SUCCESS, STATES_CREDIT_GET_ERROR,
    STATES_CREDIT_GET_REQUESTING, STATES_CREDIT_GET_SUCCESS
} from "./constants";

export const statesGeneralGetRequesting = (token) => ({
    type: STATES_GENERAL_GET_REQUESTING,
    token,
});

export const statesGeneralGetSuccess = (states) => ({
    type: STATES_GENERAL_GET_SUCCESS,
    states,
});

export const statesGeneralGetError = (error) => ({
    type: STATES_GENERAL_GET_ERROR,
    error,
});

export const statesCreditGetRequesting = (token) => ({
    type: STATES_CREDIT_GET_REQUESTING,
    token,
});

export const statesCreditGetSuccess = (states) => ({
    type: STATES_CREDIT_GET_SUCCESS,
    states,
});

export const statesCreditGetError = (error) => ({
    type: STATES_CREDIT_GET_ERROR,
    error,
});

export const stateResetStates = () => ({
    type: STATE_RESET_STATES,
});