/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

function Footer({ ...props }) {
	const { classes, fluid, white, rtlActive } = props;
	var container = cx({
		[classes.container]: !fluid,
		[classes.containerFluid]: fluid,
		[classes.whiteColor]: white
	});
	var anchor =
		classes.a +
		cx({
			[" " + classes.whiteColor]: white
		});
	var block = cx({
		[classes.block]: true,
		[classes.whiteColor]: white
	});
	return (
		<footer className={classes.footer}>
			<div className={container}>
				<div className={classes.left}>
					<List className={classes.list} style={{ display: "none" }}>
						<ListItem className={classes.inlineBlock}>
							<a href="#BackOffice" className={block}>
								{rtlActive ? "الصفحة الرئيسية" : "Home"}
							</a>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
							<a href="#Empresa" className={block}>
								{rtlActive ? "شركة" : "Compañia"}
							</a>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
							<a href="#Portafolio" className={block}>
								{rtlActive ? "بعدسة" : "Portfolio"}
							</a>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
							<a href="#blog" className={block}>
								{rtlActive ? "مدونة" : "Blog"}
							</a>
						</ListItem>
					</List>
				</div>
				<p className={classes.right}>
					&copy; {1900 + new Date().getYear()}{" "}
					<a
						href="https://digitalparchapp.com/"
						className={anchor}
						target="_blank"
					>
						{rtlActive ? "توقيت الإبداعية" : "Digital Parchapp"}
					</a>
					{rtlActive
						? ", مصنوعة مع الحب لشبكة الإنترنت أفضل"
						: ", Colombia"}
				</p>
			</div>
		</footer>
	);
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired,
	fluid: PropTypes.bool,
	white: PropTypes.bool,
	rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
