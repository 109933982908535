export const FAMILY_MEMBER_CREATE_REQUESTING = 'FAMILY_MEMBER_CREATE_REQUESTING';
export const FAMILY_MEMBER_CREATE_SUCCESS = 'FAMILY_MEMBER_CREATE_SUCCESS';
export const FAMILY_MEMBER_CREATE_ERROR = 'FAMILY_MEMBER_CREATE_ERROR';
export const FAMILY_MEMBER_UPDATE_REQUESTING = 'FAMILY_MEMBER_UPDATE_REQUESTING';
export const FAMILY_MEMBER_UPDATE_SUCCESS = 'FAMILY_MEMBER_UPDATE_SUCCESS';
export const FAMILY_MEMBER_UPDATE_ERROR = 'FAMILY_MEMBER_UPDATE_ERROR';
export const FAMILY_MEMBER_RESET_STATES = 'FAMILY_MEMBER_RESET_STATES';
export const FAMILY_MEMBER_FILL_DATA = "FAMILY_MEMBER_FILL_DATA";
export const FAMILY_MEMBER_FILL_DATA_SUCCESS = "FAMILY_MEMBER_FILL_DATA_SUCCESS";
export const FAMILY_MEMBER_FILL_DATA_ERROR = "FAMILY_MEMBER_FILL_DATA_ERROR";
export const FAMILY_MEMBER_CLEAN_VALUES = "FAMILY_MEMBER_CLEAN_VALUES";
export const FAMILY_MEMBER_DELETE_REQUESTING = "FAMILY_MEMBER_DELETE_REQUESTING";