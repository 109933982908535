import {
	SURE_TYPE_FEATURES_POLICY_ERROR,
	SURE_TYPE_FEATURES_POLICY_REQUESTING,
	SURE_TYPE_FEATURES_POLICY_SUCCESS
} from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	policyId: "",
	sureTypeFeatures: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SURE_TYPE_FEATURES_POLICY_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: "",
				policyId: action.policyId,
			};
		case SURE_TYPE_FEATURES_POLICY_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				sureTypeFeatures: action.sureTypeFeatures,
			};
		case SURE_TYPE_FEATURES_POLICY_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default reducer;