import {
    LOGIN_POST_ERROR,
    LOGIN_POST_REQUESTING,
    LOGIN_POST_SUCCESS
} from "./constants";

export const loginPostRequesting = (values) => ({
    type: LOGIN_POST_REQUESTING,
    values
});

export const loginPostSuccess = () => ({
    type: LOGIN_POST_SUCCESS,
});

export const loginPostError = (error) => ({
    type: LOGIN_POST_ERROR,
    error,
});