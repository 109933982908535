import {
	POLICY_CREATE_ERROR,
	POLICY_CREATE_REQUESTING,
	POLICY_CREATE_SUCCESS,
	POLICY_RESET_FOCUS,
	POLICY_UPDATE_ERROR,
	POLICY_UPDATE_REQUESTING,
	POLICY_UPDATE_SUCCESS,
	POLICY_DELETE_SUCCESS,
	POLICY_DELETE_REQUESTING,
	POLICY_DELETE_ERROR,
	POLICY_UPDATE_PRICE_ERROR,
	POLICY_UPDATE_PRICE_REQUESTING,
	POLICY_UPDATE_PRICE_SUCCESS,
	POLICY_CLEAN_VALUES
} from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	policy: {},
	update: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case POLICY_CREATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case POLICY_CREATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				policy: action.policy
			};
		case POLICY_CREATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case POLICY_UPDATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case POLICY_UPDATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				policy: action.policy,
				update: true
			};
		case POLICY_UPDATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case POLICY_UPDATE_PRICE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case POLICY_UPDATE_PRICE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				policy: action.policy
			};
		case POLICY_UPDATE_PRICE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case POLICY_DELETE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case POLICY_DELETE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
			};
		case POLICY_DELETE_ERROR:
			return {
				...state,
				requesting: false,
				success:false,
				error: action.error
			};
		case POLICY_RESET_FOCUS:
			return {
				...state,
				policy: {}
			};
		case POLICY_CLEAN_VALUES:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: "",
		    	policy: {}
		    };	
		default:
			return state;
	}
};

export default reducer;
