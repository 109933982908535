import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export const customInputCheckBox = ({ input, label, disabled, color, id, classes, meta: { touched, error }, ...rest }, name) => {
	return (
		<FormControlLabel
			control={
				<Checkbox disabled={disabled} color={color} value={input.value} checked={input.value} onChange={input.onChange} id={id} name={name}/>
			}
			label={label}
		/>
	);
};