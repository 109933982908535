import { INSURANCES_GET_ERROR, INSURANCES_GET_REQUESTING, INSURANCES_GET_SUCCESS } from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	insurances: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case INSURANCES_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case INSURANCES_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				insurances: action.insurances,
			};
		case INSURANCES_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default reducer;