import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    stateResetStates,
    statesGeneralGetError,
    statesGeneralGetSuccess,
    statesCreditGetError,
    statesCreditGetSuccess
} from "./actions";
import {STATES_GENERAL_GET_REQUESTING, STATES_CREDIT_GET_REQUESTING} from "./constants";

const stateUrl = `${process.env.REACT_APP_API_URL}/states`;

const statesGeneralGetApi = (token) => {
    return fetch(`${stateUrl}/general`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
	    .then(json => {
		    if (json.code === 422)
			    throw json.data;
		    if (json.code === 400)
			    throw [json.data];
		    if (json.code === 200)
			    return json.data.data;
		    throw json.data;
	    }).catch((error) => {
		    throw error;
	    });
};

function* statesGeneralGetFlow(action) {
    try {
        const {token} = action;
        const states = yield call(statesGeneralGetApi, token);
        yield put(statesGeneralGetSuccess(states));
        yield put(stateResetStates());
    } catch (error) {
        yield put(statesGeneralGetError(error));
    }
}

const statesCreditGetApi = (token) => {
    return fetch(`${stateUrl}/credit`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
	    .then(json => {
		    if (json.code === 422)
			    throw json.data;
		    if (json.code === 400)
			    throw [json.data];
		    if (json.code === 200)
			    return json.data.data;
		    throw json.data;
	    }).catch((error) => {
		    throw error;
	    });
};

function* statesCreditGetFlow(action) {
    try {
        const {token} = action;
        const states = yield call(statesCreditGetApi, token);
        yield put(statesCreditGetSuccess(states));
        yield put(stateResetStates());
    } catch (error) {
        yield put(statesCreditGetError(error));
    }
}

function* statesWatcher() {
    yield all([
        takeEvery(STATES_GENERAL_GET_REQUESTING, statesGeneralGetFlow),
        takeEvery(STATES_CREDIT_GET_REQUESTING, statesCreditGetFlow),
    ])
}

export default statesWatcher;