import {LOGOUT_GET_ERROR, LOGOUT_GET_REQUESTING, LOGOUT_GET_SUCCESS} from "./constants";

export const logoutGetRequesting = (token) => ({
    type: LOGOUT_GET_REQUESTING,
    token
});

export const logoutGetSuccess = () => ({
    type: LOGOUT_GET_SUCCESS,
});

export const logoutGetError = (error) => ({
    type: LOGOUT_GET_ERROR,
    error,
});