import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import React from "react";
import { sureTypeFeaturesPolicyError, sureTypeFeaturesPolicySuccess } from "./actions";
import { SURE_TYPE_FEATURES_POLICY_REQUESTING } from "./constants";

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const baseUrl = `${process.env.REACT_APP_API_URL}/sureTypeFeatures`;

const sureTypeFeaturesPolicyApi = (token, policyId) => {
	return fetch(`${baseUrl}/${policyId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* sureTypeFeaturesPolicyFlow(action) {
	try {
		const { token, policyId } = action;
		const sureTypeFeatures = yield call(sureTypeFeaturesPolicyApi, token, policyId);
		yield put(sureTypeFeaturesPolicySuccess(sureTypeFeatures));
	} catch (error) {
		yield put(sureTypeFeaturesPolicyError(error));
		showError(error);
	}
}

function* sureTypeFeatureWatcher() {
	yield all([
		takeEvery(SURE_TYPE_FEATURES_POLICY_REQUESTING, sureTypeFeaturesPolicyFlow),
	])
}

export default sureTypeFeatureWatcher;