import {
	DOCUMENT_TYPES_GET_ERROR,
	DOCUMENT_TYPES_GET_REQUESTING,
	DOCUMENT_TYPES_GET_SUCCESS
} from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	documentTypes: []
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case DOCUMENT_TYPES_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case DOCUMENT_TYPES_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				documentTypes: action.documentTypes
			};
		case DOCUMENT_TYPES_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		default:
			return state;
	}
};

export default reducer;