import {
    STATE_RESET_STATES,
    STATES_GENERAL_GET_ERROR,
    STATES_GENERAL_GET_REQUESTING,
    STATES_GENERAL_GET_SUCCESS, STATES_CREDIT_GET_ERROR,
    STATES_CREDIT_GET_REQUESTING, STATES_CREDIT_GET_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    states: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case STATES_GENERAL_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case STATES_GENERAL_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                states: action.states,
            };
        case STATES_GENERAL_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case STATES_CREDIT_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case STATES_CREDIT_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                states: action.states,
            };
        case STATES_CREDIT_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case STATE_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
            };
        default:
            return state;
    }
};

export default reducer;