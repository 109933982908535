export const handleFormattedNumber = (number, tag = null) => {
    const num = parseInt(number.split(".").join(""));
    try{
        const count = num.toString().split('');
        if (count.length > 3) {
            var dots = [], result = '';
            for (let i = count.length, j = 1; i > 0; i-- , j++) {
                if (j % 3 === 0)
                    dots.push('.' + count[i - 1]);
                else
                    dots.push(count[i - 1]);
            }

            for (let i = dots.length; i > 0; i--) {
                result += dots[i - 1];
            }

            if (result.split("").length > 0 && result.split("")[0] == ".")
                return result.split("").slice(1).join("");

            const format = result ? result.toString() : '';
            return tag && format ? tag + format : format; 
        } else{
            const format = num ? num.toString() : '';
            return tag && format ? tag + format : format;
        }
    }catch (error){
        console.log("error: ", error);
    }
}