import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from 'date-fns/locale/es';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

export const customInputDate = ({ input, label, disabled, color, id, classes, dateInitial, meta: { touched, error }, ...rest }, name) => {
  return (
    <MuiPickersUtilsProvider  locale={esLocale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        disabled={disabled}
        name={name}
        autoOk
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id={id}
        label={label}
        value={input.name === "fecha_final" ? input.value === "" ? dateInitial : input.value : input.value || null}
        onChange={input.onChange}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
