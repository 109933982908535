import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {citiesGetError, citiesGetSuccess} from "./actions";
import {CITIES_GET_REQUESTING} from "./constants";

const cityUrl = `${process.env.REACT_APP_API_URL}/cities`;

const citiesGetApi = () => {
    return fetch(`${cityUrl}`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
        	if (json.code === 400)
		        throw [json.data];
            if (json.code === 422)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* citiesGetFlow() {
    try {
        const cities = yield call(citiesGetApi);
        yield put(citiesGetSuccess(cities))
    } catch (error) {
        yield put(citiesGetError(error));
    }
}

function* cityWatcher() {
    yield all([
        takeEvery(CITIES_GET_REQUESTING, citiesGetFlow),
    ])
}

export default cityWatcher;