import { SURE_TYPES_GET_ERROR, SURE_TYPES_GET_REQUESTING, SURE_TYPES_GET_SUCCESS } from "./constants";

export const sureTypesGetRequesting = (token) => ({
	type: SURE_TYPES_GET_REQUESTING,
	token
});

export const sureTypesGetSuccess = (sureTypes) => ({
	type: SURE_TYPES_GET_SUCCESS,
	sureTypes
});

export const sureTypesGetError = (error) => ({
	type: SURE_TYPES_GET_ERROR,
	error
});