import {
	DOCUMENT_TYPES_GET_ERROR,
	DOCUMENT_TYPES_GET_REQUESTING,
	DOCUMENT_TYPES_GET_SUCCESS
} from "./constants";

export const documentTypesGetRequesting = () => ({
	type: DOCUMENT_TYPES_GET_REQUESTING,
});

export const documentTypesGetSuccess = (documentTypes) => ({
	type: DOCUMENT_TYPES_GET_SUCCESS,
	documentTypes,
});

export const documentTypesGetError = (error) => ({
	type: DOCUMENT_TYPES_GET_ERROR,
	error,
});