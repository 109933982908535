import React from "react";
import CustomInput from "components/CustomInput/CustomInput.jsx";

export const inputSearch = ({ input, label, id, name, type, autoComplete, placeholder, classes, rtlActive, meta: { touched, error }, ...rest }) => {
	return (
		<CustomInput
			{...rest}
			{...input}
			rtlActive={rtlActive}
			formControlProps={{
				className: classes.top + " " + classes.search
			}}
			inputProps={{
				placeholder: placeholder,
				inputProps: {
					"aria-label": placeholder,
					className: classes.searchInput,
					onChange: input.onChange,
					value: input.value
				}
			}}
		/>
	);
};