import {
	FEATURES_SURE_TYPE_GET_ERROR,
	FEATURES_SURE_TYPE_GET_REQUESTING,
	FEATURES_SURE_TYPE_GET_SUCCESS
} from "./constants";

export const featuresSureTypeGetRequesting = (token, objectId) => ({
	type: FEATURES_SURE_TYPE_GET_REQUESTING,
	token, objectId
});

export const featuresSureTypeGetSuccess = (features) => ({
	type: FEATURES_SURE_TYPE_GET_SUCCESS,
	features
});

export const featuresSureTypeGetError = (error) => ({
	type: FEATURES_SURE_TYPE_GET_ERROR,
	error
});;