import { FEATURES_SURE_TYPE_GET_ERROR, FEATURES_SURE_TYPE_GET_REQUESTING, FEATURES_SURE_TYPE_GET_SUCCESS } from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	features: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FEATURES_SURE_TYPE_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case FEATURES_SURE_TYPE_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				features: action.features,
			};
		case FEATURES_SURE_TYPE_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default reducer;