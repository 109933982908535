import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { documentTypesGetError, documentTypesGetSuccess } from "./actions";
import { DOCUMENT_TYPES_GET_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/documentTypes`;

const documentTypesGetApi = () => {
	return fetch(`${baseUrl}`, {
		method: 'GET',
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 400)
				throw [json.data];
			if (json.code === 422)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		})
};

function* documentTypesGetFlow() {
	try {
		const documentTypes = yield call(documentTypesGetApi);
		yield put(documentTypesGetSuccess(documentTypes));
	} catch (error) {
		yield put(documentTypesGetError(error));
	}
}

function* documentTypesWatcher() {
	yield all([
		takeEvery(DOCUMENT_TYPES_GET_REQUESTING, documentTypesGetFlow),
	])
}

export default documentTypesWatcher;