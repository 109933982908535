import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { insurancesGetError, insurancesGetSuccess } from "./actions";
import { INSURANCES_GET_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/insurances`;

const insurancesGetApi = (token) => {
	return fetch(`${baseUrl}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 400)
				throw [json.data];
			if (json.code === 422)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* insurancesGetFlow(action) {
	try {
		const { token } = action;
		const insurances = yield call(insurancesGetApi, token);
		yield put(insurancesGetSuccess(insurances));
	} catch (error) {
		yield put(insurancesGetError(error));
	}
}

function* insuranceWatcher() {
	yield all([
		takeEvery(INSURANCES_GET_REQUESTING, insurancesGetFlow)
	]);
}

export default insuranceWatcher;