import {
	IDENTIFICATION_TYPES_GET_ERROR,
	IDENTIFICATION_TYPES_GET_REQUESTING,
	IDENTIFICATION_TYPES_GET_SUCCESS
} from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	identificationTypes: []
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case IDENTIFICATION_TYPES_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case IDENTIFICATION_TYPES_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				identificationTypes: action.identificationTypes
			};
		case IDENTIFICATION_TYPES_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		default:
			return state;
	}
};

export default reducer;