import {
	IDENTIFICATION_TYPES_GET_ERROR,
	IDENTIFICATION_TYPES_GET_REQUESTING,
	IDENTIFICATION_TYPES_GET_SUCCESS
} from "./constants";

export const identificationTypesGetRequesting = () => ({
	type: IDENTIFICATION_TYPES_GET_REQUESTING,
});

export const identificationTypesGetSuccess = (identificationTypes) => ({
	type: IDENTIFICATION_TYPES_GET_SUCCESS,
	identificationTypes,
});

export const identificationTypesGetError = (error) => ({
	type: IDENTIFICATION_TYPES_GET_ERROR,
	error,
});