import {
	SURE_TYPE_FEATURES_POLICY_ERROR,
	SURE_TYPE_FEATURES_POLICY_REQUESTING,
	SURE_TYPE_FEATURES_POLICY_SUCCESS
} from "./constants";


export const sureTypeFeaturesPolicyRequesting = (token, policyId) => ({
	type: SURE_TYPE_FEATURES_POLICY_REQUESTING,
	token, policyId
});

export const sureTypeFeaturesPolicySuccess = (sureTypeFeatures) => ({
	type: SURE_TYPE_FEATURES_POLICY_SUCCESS,
	sureTypeFeatures
});

export const sureTypeFeaturesPolicyError = (error) => ({
	type: SURE_TYPE_FEATURES_POLICY_ERROR,
	error
});