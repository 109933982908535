import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { sureTypesGetError, sureTypesGetSuccess } from "./actions";
import { SURE_TYPES_GET_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/sureTypes`;

const sureTypesGetApi = (token) => {
	return fetch(`${baseUrl}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`
		},
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 400)
				throw [json.data];
			if (json.code === 422)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		})
};

function* sureTypesGetFlow(action) {
	try {
		const {token} = action;
		const sureTypes = yield call(sureTypesGetApi, token);
		yield put(sureTypesGetSuccess(sureTypes));
	} catch (error) {
		yield put(sureTypesGetError(error));
	}
}

function* sureTypesWatcher() {
	yield all([
		takeEvery(SURE_TYPES_GET_REQUESTING, sureTypesGetFlow),
	])
}

export default sureTypesWatcher;