import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import '../../../assets/css/modalDigitalSignature.css';

class DocuSignModal extends React.Component{
	constructor(props){
		super(props);
		this.handleCheckRender = this.handleCheckRender.bind(this);
		this.el = document.createElement("div");
		this.root = document.getElementById("root");
		this.modal = React.createRef();
	}

	handleFindKey(key){
		switch (key){
			case 'C':
			   return 'Pais';
			break;
			case 'L':
			   return 'Ciudad';
			break;
			case 'O':
			   return 'Ocupación';
			break;
			case 'OU':
			   return 'Cargo';
			break;
			case 'CN':
			   return 'Nombre'
			break;  
			case 'E':
			   return 'Email';
			break;
			case 'ST':
			   return 'Departamento';
			break; 
			default:
			  return key;  
		}
	}

	handleCheckRender(){
		const {
			document: { 
				requesting , 
				verifyData: {
					template,
					document,
					tab
				}
			}
		} = this.props;
		return <div className="modal-digital-signature-body">
		    <div class="content">
		        <h1> <Typography variant="subtitle2" gutterBottom> Información del sobre </Typography> </h1>
		        <div>
		            <Typography variant="body2" gutterBottom> <p><span className="title">Fecha de creación:</span> { new Date(template.createdDateTime).toDateString() } </p> </Typography>
		            <Typography variant="body2" gutterBottom> <p><span className="title">Fecha de modificación</span> { new Date(template.lastModifiedDateTime).toDateString() } </p> </Typography>
		            
		            <p className="divider"></p>

		            {template.hasOwnProperty("signers") && <>
		                <Typography variant="body2" gutterBottom> <p><span className="title">Firmantes</span> </p> </Typography>
		                <Typography variant="body2" gutterBottom> <p><span className="title">ID:</span> { template.signers.recipientId } </p> </Typography>
		                <Typography variant="body2" gutterBottom> <p><span className="title">Nombre:</span> { template.signers.name } </p> </Typography>
		                <Typography variant="body2" gutterBottom> <p><span className="title">Email:</span> { template.signers.email } </p> </Typography>
		                {template.signers.hasOwnProperty("signedDateTime") && 
		                    <Typography variant="body2" gutterBottom> <p><span className="title">Fecha de la firma:</span> { new Date(template.signers.signedDateTime).toDateString() } </p> </Typography>
		                }
		                <Typography variant="body2" gutterBottom> <p><span className="title">Estado:</span> { template.signers.status } </p> </Typography> </>
		            }

		            <p className="divider"></p>

		            {template.hasOwnProperty("carbonCopies") && <>
		                <Typography variant="body2" gutterBottom> <p><span className="title">Copias a destinatarios</span> </p> </Typography>
		                <Typography variant="body2" gutterBottom> <p><span className="title">ID:</span> { template.carbonCopies.recipientId } </p> </Typography>
		                <Typography variant="body2" gutterBottom> <p><span className="title">Nombre:</span> { template.carbonCopies.name } </p> </Typography>
		                <Typography variant="body2" gutterBottom> <p><span className="title">Email:</span> { template.carbonCopies.email } </p> </Typography>
		                <Typography variant="body2" gutterBottom> <p><span className="title">Estado:</span> { template.carbonCopies.status } </p> </Typography> </>
		            }
		        </div>
		    </div>

		    <div className="content">
		        <h1> <Typography variant="subtitle2" gutterBottom> Documento </Typography> </h1>
		        <div>
		            <Typography variant="body2" gutterBottom> <p><span className="title">Nombre:</span> { document.name } </p> </Typography>
		            <Typography variant="body2" gutterBottom> <p><span className="title">Tipo:</span> { document.type } </p> </Typography>
		            <Typography variant="body2" gutterBottom> <p><span className="title">Uso de plantilla:</span> { document.templateRequired ? 'SI' : 'NO' } </p> </Typography>
		        </div>
		    </div>

		    <div className="content">
		        <h1> <Typography variant="subtitle2" gutterBottom> Pestañas </Typography> </h1>
		        <div>
		            <Typography variant="body2" gutterBottom> <p><span className="title">Fecha de expedición:</span> { new Date(tab.sentDateTime).toDateString() } </p> </Typography>
		            <Typography variant="body2" gutterBottom> <p><span className="title">Estado:</span> { tab.status } </p> </Typography>
		        </div>
		    </div>
        </div>;
	}

	componentDidMount(){
		this.root.appendChild(this.el);
	}

	componentWillUnmount(){
		this.root.removeChild(this.el);
	}

	render(){
		const {
			document: { verifyData },
			handleCloseDigitalSignatureModal,
			modalRef
		} = this.props;
		const content = (
			<div className="modal-digital-signature-container" ref={modalRef}>
			    <div class="modal-digital-signature">
			    	<div className="modal-digital-signature-heading">
				       <h1> Firma digital </h1>
				    </div>
				    {!verifyData.error && this.handleCheckRender()}
				    {verifyData.error && 
			    	    <div className="empty">
			    	       <h1> No se ha encontrado el sobre </h1>
			    	    </div>
			        }
			        <div className="modal-digital-signature-footer">
				        <button className="acept" onClick={handleCloseDigitalSignatureModal}>Cerrar</button>
				    </div>
			    </div>
			</div>
		);

		return ReactDOM.createPortal(content, this.el);
	}
}

const mapStateToProps = (state) => ({
	client: state.client,
	document: state.document
});

const connected = connect(mapStateToProps, {

})(DocuSignModal);

export default connected;