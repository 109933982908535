import { INSURANCES_GET_ERROR, INSURANCES_GET_REQUESTING, INSURANCES_GET_SUCCESS } from "./constants";

export const insurancesGetRequesting = (token) => ({
	type: INSURANCES_GET_REQUESTING,
	token,
});

export const insurancesGetSuccess = (insurances) => ({
	type: INSURANCES_GET_SUCCESS,
	insurances,
});

export const insurancesGetError = (error) => ({
	type: INSURANCES_GET_ERROR,
	error,
});