import React from 'react';
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
);

const typeFiles = [
    'application/pdf',
    'image/jpeg',
    'image/png'
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.ms-powerpoint',
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // 'application/vnd.ms-excel',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'application/vnd.oasis.opendocument.presentation',
    // 'application/vnd.oasis.opendocument.spreadsheet',
    // 'application/vnd.oasis.opendocument.text',
];

const DocumentsInput = ({input, name, label, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            allowMultiple={true}
            maxFiles={rest.maxFiles || 1}
            allowFileTypeValidation={true}
            acceptedFileTypes={typeFiles}
            labelIdle={label ? label : 'Arrastra y suelta tus archivos o <span class="filepond--label-action">Busca aquí</span>'}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        />
    )
};

export default DocumentsInput;